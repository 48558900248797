.cardWrapper {
    width: 100%;
}

@media (min-width: 768px) {

    .cardWrapper {
        width: 50%;
    }
}

@media (min-width: 1024px) {

    .cardWrapper {
        width: 20%;
    }
}

.title {
    font-weight: 500 !important;
    color: var(--color-primary) !important;
    margin-bottom: 0;
    height: 46.5px !important;
    display: block;
}

.title:hover {
    text-decoration: underline !important;
}